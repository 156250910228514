import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";

class PankePrivacy extends React.Component {
  render() {
    return (
      <Layout>
        <main>
          <Helmet
            title="Privacy Policy"
            meta={[
              {
                name: "description",
                content:
                  "panke.gallery seeks to open up a dialogue between established and emerging artists whose work comes out of the connections between digital or net-based art and club culture, especially in the recent history of Berlin. Its program of exhibitions and events takes place in a gallery space within the premises of panke.club.",
              },
            ]}
          />

          <section className="info">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <h2>Privacy Policy</h2>
              </div>
              <div className="col-md-8 col-sm-8 col-xs-12">
              <p>
                  We are very delighted that you have shown interest in our
                  enterprise. Data protection is of a particularly high priority
                  for the management of the <em>panke.gallery – Verein für
                  künstlerisch-kulturelle Bildung e.V.</em>. The use of the Internet
                  pages of panke.gallery is possible without any
                  indication of personal data; however, if a data subject wants
                  to use special enterprise services via our website, processing
                  of personal data could become necessary. If the processing of
                  personal data is necessary and there is no statutory basis for
                  such processing, we generally obtain consent from the data
                  subject.
                </p>
              </div>
            </div>
          </section>
          <section className="further">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <h2>Privacy Notice</h2>
              </div>
              <div className="col-md-8 col-sm-8 col-xs-12">
                
                

                <p>
                  The processing of personal data, such as the name, address,
                  e-mail address, or telephone number of a data subject shall
                  always be in line with the General Data Protection Regulation
                  (GDPR), and in accordance with the country-specific data
                  protection regulations applicable to the panke.gallery –
                  Verein für künstlerisch-kulturelle Bildung e.V.. By means of
                  this data protection declaration, our enterprise would like to
                  inform the general public of the nature, scope, and purpose of
                  the personal data we collect, use and process. Furthermore,
                  data subjects are informed, by means of this data protection
                  declaration, of the rights to which they are entitled.
                </p>

                <p>
                  As the controller, the panke.gallery – Verein für
                  künstlerisch-kulturelle Bildung e.V. has implemented numerous
                  technical and organizational measures to ensure the most
                  complete protection of personal data processed through this
                  website. However, Internet-based data transmissions may in
                  principle have security gaps, so absolute protection may not
                  be guaranteed. For this reason, every data subject is free to
                  transfer personal data to us via alternative means, e.g. by
                  telephone.{" "}
                </p>

                <h3>1. Definitions</h3>
                <p>
                  The data protection declaration of the panke.gallery – Verein
                  für künstlerisch-kulturelle Bildung e.V. is based on the terms
                  used by the European legislator for the adoption of the
                  General Data Protection Regulation (GDPR). Our data protection
                  declaration should be legible and understandable for the
                  general public, as well as our customers and business
                  partners. To ensure this, we would like to first explain the
                  terminology used.
                </p>

                <p>
                  In this data protection declaration, we use, inter alia, the
                  following terms:
                </p>

                <ul style={{ listStyle: "none" }}>
                  <li>
                    <h3>a)    Personal data</h3>
                    <p>
                      Personal data means any information relating to an
                      identified or identifiable natural person (“data
                      subject”). An identifiable natural person is one who can
                      be identified, directly or indirectly, in particular by
                      reference to an identifier such as a name, an
                      identification number, location data, an online identifier
                      or to one or more factors specific to the physical,
                      physiological, genetic, mental, economic, cultural or
                      social identity of that natural person.
                    </p>
                  </li>
                  <li>
                    <h3>b) Data subject</h3>
                    <p>
                      Data subject is any identified or identifiable natural
                      person, whose personal data is processed by the controller
                      responsible for the processing.
                    </p>
                  </li>
                  <li>
                    <h3>c)    Processing</h3>
                    <p>
                      Processing is any operation or set of operations which is
                      performed on personal data or on sets of personal data,
                      whether or not by automated means, such as collection,
                      recording, organisation, structuring, storage, adaptation
                      or alteration, retrieval, consultation, use, disclosure by
                      transmission, dissemination or otherwise making available,
                      alignment or combination, restriction, erasure or
                      destruction.{" "}
                    </p>
                  </li>
                  <li>
                    <h3>d)    Restriction of processing</h3>
                    <p>
                      Restriction of processing is the marking of stored
                      personal data with the aim of limiting their processing in
                      the future.{" "}
                    </p>
                  </li>
                  <li>
                    <h3>e)    Profiling</h3>
                    <p>
                      Profiling means any form of automated processing of
                      personal data consisting of the use of personal data to
                      evaluate certain personal aspects relating to a natural
                      person, in particular to analyse or predict aspects
                      concerning that natural person's performance at work,
                      economic situation, health, personal preferences,
                      interests, reliability, behaviour, location or movements.{" "}
                    </p>
                  </li>
                  <li>
                    <h3>f)     Pseudonymisation</h3>
                    <p>
                      Pseudonymisation is the processing of personal data in
                      such a manner that the personal data can no longer be
                      attributed to a specific data subject without the use of
                      additional information, provided that such additional
                      information is kept separately and is subject to technical
                      and organisational measures to ensure that the personal
                      data are not attributed to an identified or identifiable
                      natural person.{" "}
                    </p>
                  </li>
                  <li>
                    <h3>
                      g)    Controller or controller responsible for the
                      processing
                    </h3>
                    <p>
                      Controller or controller responsible for the processing is
                      the natural or legal person, public authority, agency or
                      other body which, alone or jointly with others, determines
                      the purposes and means of the processing of personal data;
                      where the purposes and means of such processing are
                      determined by Union or Member State law, the controller or
                      the specific criteria for its nomination may be provided
                      for by Union or Member State law.{" "}
                    </p>
                  </li>
                  <li>
                    <h3>h)    Processor</h3>
                    <p>
                      Processor is a natural or legal person, public authority,
                      agency or other body which processes personal data on
                      behalf of the controller.{" "}
                    </p>
                  </li>
                  <li>
                    <h3>i)      Recipient</h3>
                    <p>
                      Recipient is a natural or legal person, public authority,
                      agency or another body, to which the personal data are
                      disclosed, whether a third party or not. However, public
                      authorities which may receive personal data in the
                      framework of a particular inquiry in accordance with Union
                      or Member State law shall not be regarded as recipients;
                      the processing of those data by those public authorities
                      shall be in compliance with the applicable data protection
                      rules according to the purposes of the processing.{" "}
                    </p>
                  </li>
                  <li>
                    <h3>j)      Third party</h3>
                    <p>
                      Third party is a natural or legal person, public
                      authority, agency or body other than the data subject,
                      controller, processor and persons who, under the direct
                      authority of the controller or processor, are authorised
                      to process personal data.
                    </p>
                  </li>
                  <li>
                    <h3>k)    Consent</h3>
                    <p>
                      Consent of the data subject is any freely given, specific,
                      informed and unambiguous indication of the data subject's
                      wishes by which he or she, by a statement or by a clear
                      affirmative action, signifies agreement to the processing
                      of personal data relating to him or her.{" "}
                    </p>
                  </li>
                </ul>

                <h3>2. Name and Address of the controller</h3>
                <p>
                  Controller for the purposes of the General Data Protection
                  Regulation (GDPR), other data protection laws applicable in
                  Member states of the European Union and other provisions
                  related to data protection is:
                </p>

                <p>
                  panke.gallery – Verein für künstlerisch-kulturelle Bildung
                  e.V.
                  <br/>Gerichtstr. 23, 13347 Berlin, Germany</p>
                <p>info@panke.gallery · www.panke.gallery</p>

                <h3>3. Collection of general data and information</h3>
                <p>
                  The website of the panke.gallery – Verein für
                  künstlerisch-kulturelle Bildung e.V. collects a series of
                  general data and information when a data subject or automated
                  system calls up the website. This general data and information
                  are stored in the server log files. Collected may be (1) the
                  browser types and versions used, (2) the operating system used
                  by the accessing system, (3) the website from which an
                  accessing system reaches our website (so-called referrers),
                  (4) the sub-websites, (5) the date and time of access to the
                  Internet site, (6) an Internet protocol address (IP address),
                  (7) the Internet service provider of the accessing system, and
                  (8) any other similar data and information that may be used in
                  the event of attacks on our information technology systems.
                </p>

                <p>
                  When using these general data and information, the
                  panke.gallery – Verein für künstlerisch-kulturelle Bildung
                  e.V. does not draw any conclusions about the data subject.
                  Rather, this information is needed to (1) deliver the content
                  of our website correctly, (2) optimize the content of our
                  website as well as its advertisement, (3) ensure the long-term
                  viability of our information technology systems and website
                  technology, and (4) provide law enforcement authorities with
                  the information necessary for criminal prosecution in case of
                  a cyber-attack. Therefore, the panke.gallery – Verein für
                  künstlerisch-kulturelle Bildung e.V. analyzes anonymously
                  collected data and information statistically, with the aim of
                  increasing the data protection and data security of our
                  enterprise, and to ensure an optimal level of protection for
                  the personal data we process. The anonymous data of the server
                  log files are stored separately from all personal data
                  provided by a data subject.
                </p>

                <h3>4. Subscription to our newsletters</h3>
                <p>
                  On the website of the panke.gallery – Verein für
                  künstlerisch-kulturelle Bildung e.V., users are given the
                  opportunity to subscribe to our enterprise's newsletter. The
                  input mask used for this purpose determines what personal data
                  are transmitted, as well as when the newsletter is ordered
                  from the controller.
                </p>

                <p>
                  The panke.gallery – Verein für künstlerisch-kulturelle Bildung
                  e.V. informs its customers and business partners regularly by
                  means of a newsletter about enterprise offers. The
                  enterprise's newsletter may only be received by the data
                  subject if (1) the data subject has a valid e-mail address and
                  (2) the data subject registers for the newsletter shipping. A
                  confirmation e-mail will be sent to the e-mail address
                  registered by a data subject for the first time for newsletter
                  shipping, for legal reasons, in the double opt-in procedure.
                  This confirmation e-mail is used to prove whether the owner of
                  the e-mail address as the data subject is authorized to
                  receive the newsletter.
                </p>

                <p>
                  During the registration for the newsletter, we also store the
                  IP address of the computer system assigned by the Internet
                  service provider (ISP) and used by the data subject at the
                  time of the registration, as well as the date and time of the
                  registration. The collection of this data is necessary in
                  order to understand the (possible) misuse of the e-mail
                  address of a data subject at a later date, and it therefore
                  serves the aim of the legal protection of the controller.
                </p>

                <p>
                  The personal data collected as part of a registration for the
                  newsletter will only be used to send our newsletter. In
                  addition, subscribers to the newsletter may be informed by
                  e-mail, as long as this is necessary for the operation of the
                  newsletter service or a registration in question, as this
                  could be the case in the event of modifications to the
                  newsletter offer, or in the event of a change in technical
                  circumstances. There will be no transfer of personal data
                  collected by the newsletter service to third parties. The
                  subscription to our newsletter may be terminated by the data
                  subject at any time. The consent to the storage of personal
                  data, which the data subject has given for shipping the
                  newsletter, may be revoked at any time. For the purpose of
                  revocation of consent, a corresponding link is found in each
                  newsletter. It is also possible to unsubscribe from the
                  newsletter at any time directly on the website of the
                  controller, or to communicate this to the controller in a
                  different way.
                </p>

                <h3>5. Newsletter-Tracking</h3>
                <p>
                  The newsletter of the panke.gallery – Verein für
                  künstlerisch-kulturelle Bildung e.V. contains so-called
                  tracking pixels. A tracking pixel is a miniature graphic
                  embedded in such e-mails, which are sent in HTML format to
                  enable log file recording and analysis. This allows a
                  statistical analysis of the success or failure of online
                  marketing campaigns. Based on the embedded tracking pixel, the
                  panke.gallery – Verein für künstlerisch-kulturelle Bildung
                  e.V. may see if and when an e-mail was opened by a data
                  subject, and which links in the e-mail were called up by data
                  subjects.
                </p>

                <p>
                  Such personal data collected in the tracking pixels contained
                  in the newsletters are stored and analyzed by the controller
                  in order to optimize the shipping of the newsletter, as well
                  as to adapt the content of future newsletters even better to
                  the interests of the data subject. These personal data will
                  not be passed on to third parties. Data subjects are at any
                  time entitled to revoke the respective separate declaration of
                  consent issued by means of the double-opt-in procedure. After
                  a revocation, these personal data will be deleted by the
                  controller. The panke.gallery – Verein für
                  künstlerisch-kulturelle Bildung e.V. automatically regards a
                  withdrawal from the receipt of the newsletter as a revocation.
                </p>

                <h3>6. Routine erasure and blocking of personal data</h3>
                <p>
                  The data controller shall process and store the personal data
                  of the data subject only for the period necessary to achieve
                  the purpose of storage, or as far as this is granted by the
                  European legislator or other legislators in laws or
                  regulations to which the controller is subject to.
                </p>

                <p>
                  If the storage purpose is not applicable, or if a storage
                  period prescribed by the European legislator or another
                  competent legislator expires, the personal data are routinely
                  blocked or erased in accordance with legal requirements.
                </p>

                <h3>7. Rights of the data subject</h3>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <h3>a) Right of confirmation</h3>
                    <p>
                      Each data subject shall have the right granted by the
                      European legislator to obtain from the controller the
                      confirmation as to whether or not personal data concerning
                      him or her are being processed. If a data subject wishes
                      to avail himself of this right of confirmation, he or she
                      may, at any time, contact any employee of the controller.
                    </p>
                  </li>
                  <li>
                    <h3>b) Right of access</h3>
                    <p>
                      Each data subject shall have the right granted by the
                      European legislator to obtain from the controller free
                      information about his or her personal data stored at any
                      time and a copy of this information. Furthermore, the
                      European directives and regulations grant the data subject
                      access to the following information:
                    </p>

                    <ul style={{ listStyle: "none" }}>
                      <li>the purposes of the processing;</li>
                      <li>the categories of personal data concerned;</li>
                      <li>
                        the recipients or categories of recipients to whom the
                        personal data have been or will be disclosed, in
                        particular recipients in third countries or
                        international organisations;
                      </li>
                      <li>
                        where possible, the envisaged period for which the
                        personal data will be stored, or, if not possible, the
                        criteria used to determine that period;
                      </li>
                      <li>
                        the existence of the right to request from the
                        controller rectification or erasure of personal data, or
                        restriction of processing of personal data concerning
                        the data subject, or to object to such processing;
                      </li>
                      <li>
                        the existence of the right to lodge a complaint with a
                        supervisory authority;
                      </li>
                      <li>
                        where the personal data are not collected from the data
                        subject, any available information as to their source;
                      </li>
                      <li>
                        the existence of automated decision-making, including
                        profiling, referred to in Article 22(1) and (4) of the
                        GDPR and, at least in those cases, meaningful
                        information about the logic involved, as well as the
                        significance and envisaged consequences of such
                        processing for the data subject.
                      </li>
                    </ul>
                    <p>
                      Furthermore, the data subject shall have a right to obtain
                      information as to whether personal data are transferred to
                      a third country or to an international organisation. Where
                      this is the case, the data subject shall have the right to
                      be informed of the appropriate safeguards relating to the
                      transfer.
                    </p>

                    <p>
                      If a data subject wishes to avail himself of this right of
                      access, he or she may, at any time, contact any employee
                      of the controller.
                    </p>
                  </li>
                  <li>
                    <h3>c) Right to rectification </h3>
                    <p>
                      Each data subject shall have the right granted by the
                      European legislator to obtain from the controller without
                      undue delay the rectification of inaccurate personal data
                      concerning him or her. Taking into account the purposes of
                      the processing, the data subject shall have the right to
                      have incomplete personal data completed, including by
                      means of providing a supplementary statement.
                    </p>

                    <p>
                      If a data subject wishes to exercise this right to
                      rectification, he or she may, at any time, contact any
                      employee of the controller.
                    </p>
                  </li>
                  <li>
                    <h3>d) Right to erasure (Right to be forgotten) </h3>
                    <p>
                      Each data subject shall have the right granted by the
                      European legislator to obtain from the controller the
                      erasure of personal data concerning him or her without
                      undue delay, and the controller shall have the obligation
                      to erase personal data without undue delay where one of
                      the following grounds applies, as long as the processing
                      is not necessary:{" "}
                    </p>

                    <ul style={{ listStyle: "none" }}>
                      <li>
                        The personal data are no longer necessary in relation to
                        the purposes for which they were collected or otherwise
                        processed.
                      </li>
                      <li>
                        The data subject withdraws consent to which the
                        processing is based according to point (a) of Article
                        6(1) of the GDPR, or point (a) of Article 9(2) of the
                        GDPR, and where there is no other legal ground for the
                        processing.
                      </li>
                      <li>
                        The data subject objects to the processing pursuant to
                        Article 21(1) of the GDPR and there are no overriding
                        legitimate grounds for the processing, or the data
                        subject objects to the processing pursuant to Article
                        21(2) of the GDPR.{" "}
                      </li>
                      <li>The personal data have been unlawfully processed.</li>
                      <li>
                        The personal data must be erased for compliance with a
                        legal obligation in Union or Member State law to which
                        the controller is subject.
                      </li>
                      <li>
                        The personal data have been collected in relation to the
                        offer of information society services referred to in
                        Article 8(1) of the GDPR.
                      </li>
                    </ul>
                    <p>
                      If one of the aforementioned reasons applies, and a data
                      subject wishes to request the erasure of personal data
                      stored by the panke.gallery – Verein für
                      künstlerisch-kulturelle Bildung e.V., he or she may, at
                      any time, contact any employee of the controller. An
                      employee of panke.gallery – Verein für
                      künstlerisch-kulturelle Bildung e.V. shall promptly ensure
                      that the erasure request is complied with immediately.
                    </p>

                    <p>
                      Where the controller has made personal data public and is
                      obliged pursuant to Article 17(1) to erase the personal
                      data, the controller, taking account of available
                      technology and the cost of implementation, shall take
                      reasonable steps, including technical measures, to inform
                      other controllers processing the personal data that the
                      data subject has requested erasure by such controllers of
                      any links to, or copy or replication of, those personal
                      data, as far as processing is not required. An employees
                      of the panke.gallery – Verein für künstlerisch-kulturelle
                      Bildung e.V. will arrange the necessary measures in
                      individual cases.
                    </p>
                  </li>
                  <li>
                    <h3>e) Right of restriction of processing</h3>
                    <p>
                      Each data subject shall have the right granted by the
                      European legislator to obtain from the controller
                      restriction of processing where one of the following
                      applies:
                    </p>

                    <ul style={{ listStyle: "none" }}>
                      <li>
                        The accuracy of the personal data is contested by the
                        data subject, for a period enabling the controller to
                        verify the accuracy of the personal data.{" "}
                      </li>
                      <li>
                        The processing is unlawful and the data subject opposes
                        the erasure of the personal data and requests instead
                        the restriction of their use instead.
                      </li>
                      <li>
                        The controller no longer needs the personal data for the
                        purposes of the processing, but they are required by the
                        data subject for the establishment, exercise or defence
                        of legal claims.
                      </li>
                      <li>
                        The data subject has objected to processing pursuant to
                        Article 21(1) of the GDPR pending the verification
                        whether the legitimate grounds of the controller
                        override those of the data subject.
                      </li>
                    </ul>
                    <p>
                      If one of the aforementioned conditions is met, and a data
                      subject wishes to request the restriction of the
                      processing of personal data stored by the panke.gallery –
                      Verein für künstlerisch-kulturelle Bildung e.V., he or she
                      may at any time contact any employee of the controller.
                      The employee of the panke.gallery – Verein für
                      künstlerisch-kulturelle Bildung e.V. will arrange the
                      restriction of the processing.{" "}
                    </p>
                  </li>
                  <li>
                    <h3>f) Right to data portability</h3>
                    <p>
                      Each data subject shall have the right granted by the
                      European legislator, to receive the personal data
                      concerning him or her, which was provided to a controller,
                      in a structured, commonly used and machine-readable
                      format. He or she shall have the right to transmit those
                      data to another controller without hindrance from the
                      controller to which the personal data have been provided,
                      as long as the processing is based on consent pursuant to
                      point (a) of Article 6(1) of the GDPR or point (a) of
                      Article 9(2) of the GDPR, or on a contract pursuant to
                      point (b) of Article 6(1) of the GDPR, and the processing
                      is carried out by automated means, as long as the
                      processing is not necessary for the performance of a task
                      carried out in the public interest or in the exercise of
                      official authority vested in the controller.
                    </p>

                    <p>
                      Furthermore, in exercising his or her right to data
                      portability pursuant to Article 20(1) of the GDPR, the
                      data subject shall have the right to have personal data
                      transmitted directly from one controller to another, where
                      technically feasible and when doing so does not adversely
                      affect the rights and freedoms of others.
                    </p>

                    <p>
                      In order to assert the right to data portability, the data
                      subject may at any time contact any employee of the
                      panke.gallery – Verein für künstlerisch-kulturelle Bildung
                      e.V..
                    </p>
                  </li>
                  <li>
                    <h3>g) Right to object</h3>
                    <p>
                      Each data subject shall have the right granted by the
                      European legislator to object, on grounds relating to his
                      or her particular situation, at any time, to processing of
                      personal data concerning him or her, which is based on
                      point (e) or (f) of Article 6(1) of the GDPR. This also
                      applies to profiling based on these provisions.
                    </p>

                    <p>
                      The panke.gallery – Verein für künstlerisch-kulturelle
                      Bildung e.V. shall no longer process the personal data in
                      the event of the objection, unless we can demonstrate
                      compelling legitimate grounds for the processing which
                      override the interests, rights and freedoms of the data
                      subject, or for the establishment, exercise or defence of
                      legal claims.
                    </p>

                    <p>
                      If the panke.gallery – Verein für künstlerisch-kulturelle
                      Bildung e.V. processes personal data for direct marketing
                      purposes, the data subject shall have the right to object
                      at any time to processing of personal data concerning him
                      or her for such marketing. This applies to profiling to
                      the extent that it is related to such direct marketing. If
                      the data subject objects to the panke.gallery – Verein für
                      künstlerisch-kulturelle Bildung e.V. to the processing for
                      direct marketing purposes, the panke.gallery – Verein für
                      künstlerisch-kulturelle Bildung e.V. will no longer
                      process the personal data for these purposes.
                    </p>

                    <p>
                      In addition, the data subject has the right, on grounds
                      relating to his or her particular situation, to object to
                      processing of personal data concerning him or her by the
                      panke.gallery – Verein für künstlerisch-kulturelle Bildung
                      e.V. for scientific or historical research purposes, or
                      for statistical purposes pursuant to Article 89(1) of the
                      GDPR, unless the processing is necessary for the
                      performance of a task carried out for reasons of public
                      interest.
                    </p>

                    <p>
                      In order to exercise the right to object, the data subject
                      may contact any employee of the panke.gallery – Verein für
                      künstlerisch-kulturelle Bildung e.V.. In addition, the
                      data subject is free in the context of the use of
                      information society services, and notwithstanding
                      Directive 2002/58/EC, to use his or her right to object by
                      automated means using technical specifications.
                    </p>
                  </li>
                  <li>
                    <h3>
                      h) Automated individual decision-making, including
                      profiling
                    </h3>
                    <p>
                      Each data subject shall have the right granted by the
                      European legislator not to be subject to a decision based
                      solely on automated processing, including profiling, which
                      produces legal effects concerning him or her, or similarly
                      significantly affects him or her, as long as the decision
                      (1) is not is necessary for entering into, or the
                      performance of, a contract between the data subject and a
                      data controller, or (2) is not authorised by Union or
                      Member State law to which the controller is subject and
                      which also lays down suitable measures to safeguard the
                      data subject's rights and freedoms and legitimate
                      interests, or (3) is not based on the data subject's
                      explicit consent.
                    </p>

                    <p>
                      If the decision (1) is necessary for entering into, or the
                      performance of, a contract between the data subject and a
                      data controller, or (2) it is based on the data subject's
                      explicit consent, the panke.gallery – Verein für
                      künstlerisch-kulturelle Bildung e.V. shall implement
                      suitable measures to safeguard the data subject's rights
                      and freedoms and legitimate interests, at least the right
                      to obtain human intervention on the part of the
                      controller, to express his or her point of view and
                      contest the decision.
                    </p>

                    <p>
                      If the data subject wishes to exercise the rights
                      concerning automated individual decision-making, he or she
                      may, at any time, contact any employee of the
                      panke.gallery – Verein für künstlerisch-kulturelle Bildung
                      e.V..
                    </p>
                  </li>
                  <li>
                    <h3>i) Right to withdraw data protection consent </h3>
                    <p>
                      Each data subject shall have the right granted by the
                      European legislator to withdraw his or her consent to
                      processing of his or her personal data at any time.{" "}
                    </p>

                    <p>
                      If the data subject wishes to exercise the right to
                      withdraw the consent, he or she may, at any time, contact
                      any employee of the panke.gallery – Verein für
                      künstlerisch-kulturelle Bildung e.V..
                    </p>
                  </li>
                </ul>
                <h3>
                  8. Data protection provisions about the application and use of
                  Matomo
                </h3>
                <p>
                  On this website, the controller has integrated the Matomo
                  component. Matomo is an open-source software tool for web
                  analysis. Web analysis is the collection, gathering and
                  evaluation of data on the behavior of visitors from Internet
                  sites. A web analysis tool collects, inter alia, data on the
                  website from which a data subject came to a website (so-called
                  referrer), which pages of the website were accessed or how
                  often and for which period of time a sub-page was viewed. A
                  web analysis is mainly used for the optimization of a website
                  and the cost-benefit analysis of Internet advertising.
                </p>

                <p>
                  The software is operated on the server of the controller, the
                  data protection-sensitive log files are stored exclusively on
                  this server.
                </p>

                <p>
                  The purpose of the Matomo component is the analysis of the
                  visitor flows on our website. The controller uses the obtained
                  data and information, inter alia, to evaluate the use of this
                  website in order to compile online reports, which show the
                  activities on our Internet pages.
                </p>

                <p>
                  Matomo sets a cookie on the information technology system of
                  the data subject. The definition of cookies is explained
                  above. With the setting of the cookie, an analysis of the use
                  of our website is enabled. With each call-up to one of the
                  individual pages of this website, the Internet browser on the
                  information technology system of the data subject is
                  automatically through the Matomo component prompted to submit
                  data for the purpose of online analysis to our server. During
                  the course of this technical procedure, we obtain knowledge
                  about personal information, such as the IP address of the data
                  subject, which serves to understand the origin of visitors and
                  clicks.
                </p>

                <p>
                  The cookie is used to store personal information, such as the
                  access time, the location from which access was made, and the
                  frequency of visits to our website. With each visit of our
                  Internet pages, these personal data, including the IP address
                  of the Internet access used by the data subject, are
                  transferred to our server. These personal data will be stored
                  by us. We do not forward this personal data to third parties.
                </p>

                <p>
                  The data subject may, as stated above, prevent the setting of
                  cookies through our website at any time by means of a
                  corresponding adjustment of the web browser used and thus
                  permanently deny the setting of cookies. Such an adjustment to
                  the used Internet browser would also prevent Matomo from
                  setting a cookie on the information technology system of the
                  data subject. In addition, cookies already in use by Matomo
                  may be deleted at any time via a web browser or other software
                  programs.
                </p>

                <p>
                  In addition, the data subject has the possibility of objecting
                  to a collection of data relating to a use of this Internet
                  site that are generated by Matomo as well as the processing of
                  these data by Matomo and the chance to preclude any such. For
                  this, the data subject must set a "Do Not Track" option in the
                  browser.
                </p>

                <p>
                  With each setting of the opt-out cookie, however, there is the
                  possibility that the websites of the controller are no longer
                  fully usable for the data subject.
                </p>

                <p>
                  Further information and the applicable data protection
                  provisions of Matomo may be retrieved under
                  https://matomo.org/privacy/.
                </p>

                <h3>
                  9. Data protection provisions about the application and use of
                  YouTube
                </h3>
                <p>
                  On this website, the controller has integrated components of
                  YouTube. YouTube is an Internet video portal that enables
                  video publishers to set video clips and other users free of
                  charge, which also provides free viewing, review and
                  commenting on them. YouTube allows you to publish all kinds of
                  videos, so you can access both full movies and TV broadcasts,
                  as well as music videos, trailers, and videos made by users
                  via the Internet portal.
                </p>

                <p>
                  The operating company of YouTube is Google Ireland Limited,
                  Gordon House, Barrow Street, Dublin, D04 E5W5, Ireland.
                </p>

                <p>
                  With each call-up to one of the individual pages of this
                  Internet site, which is operated by the controller and on
                  which a YouTube component (YouTube video) was integrated, the
                  Internet browser on the information technology system of the
                  data subject is automatically prompted to download a display
                  of the corresponding YouTube component. Further information
                  about YouTube may be obtained under
                  https://www.youtube.com/yt/about/en/. During the course of
                  this technical procedure, YouTube and Google gain knowledge of
                  what specific sub-page of our website was visited by the data
                  subject.
                </p>

                <p>
                  If the data subject is logged in on YouTube, YouTube
                  recognizes with each call-up to a sub-page that contains a
                  YouTube video, which specific sub-page of our Internet site
                  was visited by the data subject. This information is collected
                  by YouTube and Google and assigned to the respective YouTube
                  account of the data subject.
                </p>

                <p>
                  YouTube and Google will receive information through the
                  YouTube component that the data subject has visited our
                  website, if the data subject at the time of the call to our
                  website is logged in on YouTube; this occurs regardless of
                  whether the person clicks on a YouTube video or not. If such a
                  transmission of this information to YouTube and Google is not
                  desirable for the data subject, the delivery may be prevented
                  if the data subject logs off from their own YouTube account
                  before a call-up to our website is made.
                </p>

                <p>
                  YouTube's data protection provisions, available at
                  https://www.google.com/intl/en/policies/privacy/, provide
                  information about the collection, processing and use of
                  personal data by YouTube and Google.
                </p>

                <h3>10. Legal basis for the processing </h3>
                <p>
                  Art. 6(1) lit. a GDPR serves as the legal basis for processing
                  operations for which we obtain consent for a specific
                  processing purpose. If the processing of personal data is
                  necessary for the performance of a contract to which the data
                  subject is party, as is the case, for example, when processing
                  operations are necessary for the supply of goods or to provide
                  any other service, the processing is based on Article 6(1)
                  lit. b GDPR. The same applies to such processing operations
                  which are necessary for carrying out pre-contractual measures,
                  for example in the case of inquiries concerning our products
                  or services. Is our company subject to a legal obligation by
                  which processing of personal data is required, such as for the
                  fulfillment of tax obligations, the processing is based on
                  Art. 6(1) lit. c GDPR. In rare cases, the processing of
                  personal data may be necessary to protect the vital interests
                  of the data subject or of another natural person. This would
                  be the case, for example, if a visitor were injured in our
                  company and his name, age, health insurance data or other
                  vital information would have to be passed on to a doctor,
                  hospital or other third party. Then the processing would be
                  based on Art. 6(1) lit. d GDPR. Finally, processing operations
                  could be based on Article 6(1) lit. f GDPR. This legal basis
                  is used for processing operations which are not covered by any
                  of the abovementioned legal grounds, if processing is
                  necessary for the purposes of the legitimate interests pursued
                  by our company or by a third party, except where such
                  interests are overridden by the interests or fundamental
                  rights and freedoms of the data subject which require
                  protection of personal data. Such processing operations are
                  particularly permissible because they have been specifically
                  mentioned by the European legislator. He considered that a
                  legitimate interest could be assumed if the data subject is a
                  client of the controller (Recital 47 Sentence 2 GDPR).
                </p>

                <h3>
                  11. The legitimate interests pursued by the controller or by a
                  third party
                </h3>
                <p>
                  Where the processing of personal data is based on Article 6(1)
                  lit. f GDPR our legitimate interest is to carry out our
                  business in favor of the well-being of all our employees and
                  the shareholders.
                </p>

                <h3>12. Period for which the personal data will be stored</h3>
                <p>
                  The criteria used to determine the period of storage of
                  personal data is the respective statutory retention period.
                  After expiration of that period, the corresponding data is
                  routinely deleted, as long as it is no longer necessary for
                  the fulfillment of the contract or the initiation of a
                  contract.
                </p>

                <h3>
                  13. Provision of personal data as statutory or contractual
                  requirement; Requirement necessary to enter into a contract;
                  Obligation of the data subject to provide the personal data;
                  possible consequences of failure to provide such data{" "}
                </h3>
                <p>
                  We clarify that the provision of personal data is partly
                  required by law (e.g. tax regulations) or can also result from
                  contractual provisions (e.g. information on the contractual
                  partner). Sometimes it may be necessary to conclude a contract
                  that the data subject provides us with personal data, which
                  must subsequently be processed by us. The data subject is, for
                  example, obliged to provide us with personal data when our
                  company signs a contract with him or her. The non-provision of
                  the personal data would have the consequence that the contract
                  with the data subject could not be concluded. Before personal
                  data is provided by the data subject, the data subject must
                  contact any employee. The employee clarifies to the data
                  subject whether the provision of the personal data is required
                  by law or contract or is necessary for the conclusion of the
                  contract, whether there is an obligation to provide the
                  personal data and the consequences of non-provision of the
                  personal data.
                </p>

                <h3>14. Existence of automated decision-making</h3>
                <p>
                  As a responsible company, we do not use automatic
                  decision-making or profiling.
                </p>

                <small>
                  Developed by the specialists for{" "}
                  <a href="https://willing-able.com/">LegalTech</a> at Willing &
                  Able that also developed the system for{" "}
                  <a href="https://abletocontract.com/">document esign</a>. The
                  legal texts contained in our privacy policy generator have
                  been provided and published by{" "}
                  <a href="https://dg-datenschutz.de/">
                    Prof. Dr. h.c. Heiko Jonny Maniero
                  </a>{" "}
                  from the German Association for Data Protection and{" "}
                  <a href="https://www.wbs-law.de/" rel="nofollow">
                    Christian Solmecke
                  </a>{" "}
                  from WBS law.
                </small>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    );
  }
}

export default PankePrivacy;
